import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, List, message } from 'antd';
import axiosInstance from '../services/axiosConfig';

const EditarPeritosModal = ({ visible, onClose, procedimiento, onUpdate }) => {
    const [loading, setLoading] = useState(false);

    // Lista completa de peritos (usuarios con `especialidad_pericial`)
    const [availablePeritos, setAvailablePeritos] = useState([]);
    // Peritos ya asignados al procedimiento (vienen en `procedimiento.peritos_asignados`)
    const [peritosSeleccionados, setPeritosSeleccionados] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Cargar la lista de peritos (todos o filtrar) al montar o cuando visible = true
    useEffect(() => {
        if (!visible) return;

        const fetchPeritos = async () => {
            try {
                // Si quisieras filtrar por searchTerm en el back, podrías usar:
                // const response = await axiosInstance.get('/usuarios/', { params: { search: searchTerm } });
                // Por ahora, cargamos todos y filtramos en front
                const response = await axiosInstance.get('/usuarios/');
                // Filtrar sólo los que tengan `especialidad_pericial`
                const peritos = response.data.filter(u => u.especialidad_pericial != null);
                setAvailablePeritos(peritos);
            } catch (error) {
                console.error('Error al cargar los peritos:', error);
                message.error('No se pudieron cargar los peritos.');
            }
        };

        fetchPeritos();
        // Copia inicial de los peritos asignados en el procedimiento
        setPeritosSeleccionados(procedimiento.peritos_asignados || []);
        setSearchTerm('');
    }, [visible, procedimiento]);

    // Filtrado en front: si searchTerm, filtra `availablePeritos`
    const filteredPeritos = availablePeritos.filter(perito => {
        const fullName = `${perito.nombre} ${perito.apellidos}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    });

    const handleAddPerito = (perito) => {
        // Evitar duplicados
        if (!peritosSeleccionados.some(p => p.id === perito.id)) {
            setPeritosSeleccionados([...peritosSeleccionados, perito]);
        }
    };

    const handleRemovePerito = (peritoId) => {
        setPeritosSeleccionados(
            peritosSeleccionados.filter(per => per.id !== peritoId)
        );
    };

    const handleSave = async () => {
        if (!procedimiento) return;
        setLoading(true);

        try {
            // Si hay al menos un perito, pasamos a estado 'asignado_peritos'
            const estado = (peritosSeleccionados.length > 0)
                ? 'asignado_peritos'
                : procedimiento.estado;

            const response = await axiosInstance.patch(`/procedimientos/${procedimiento.id}/`, {
                peritos_asignados: peritosSeleccionados.map(p => p.id),
                estado: estado
            });

            if (response.status === 200) {
                message.success('Peritos asignados correctamente.');
                // Actualizar en el front
                onUpdate({
                    ...procedimiento,
                    peritos_asignados: peritosSeleccionados,
                    estado: estado
                });
                onClose();
            }
        } catch (error) {
            console.error('Error al actualizar los peritos asignados:', error);
            message.error('No se pudo asignar los peritos.');
        } finally {
            setLoading(false);
        }
    };

    // Render
    return (
        <Modal
            title="Editar Peritos Asignados"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            width={800}
        >
            {/* Buscador */}
            <Input.Search
                placeholder="Buscar peritos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: 16 }}
            />

            {/* LISTA DE PERITOS DISPONIBLES */}
            <h3>Peritos Disponibles</h3>
            <div style={{ maxHeight: 200, overflowY: 'auto', marginBottom: 20 }}>
                <List
                    bordered
                    dataSource={filteredPeritos}
                    rowKey={(item) => item.id}
                    renderItem={(perito) => {
                        const fullName = `${perito.nombre} ${perito.apellidos}`;
                        const espec = perito.especialidad_pericial?.nombre || 'Sin especialidad';
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        type="link"
                                        onClick={() => handleAddPerito(perito)}
                                    >
                                        Añadir
                                    </Button>
                                ]}
                            >
                                {fullName} ({espec})
                            </List.Item>
                        );
                    }}
                />
            </div>

            {/* LISTA DE PERITOS ASIGNADOS */}
            <h3>Peritos Seleccionados</h3>
            <div style={{ maxHeight: 200, overflowY: 'auto', marginBottom: 20 }}>
                <List
                    bordered
                    dataSource={peritosSeleccionados}
                    rowKey={(item) => item.id}
                    renderItem={(perito) => {
                        const fullName = `${perito.nombre} ${perito.apellidos}`;
                        const espec = perito.especialidad_pericial?.nombre || 'Sin especialidad';
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        danger
                                        type="link"
                                        onClick={() => handleRemovePerito(perito.id)}
                                    >
                                        Eliminar
                                    </Button>
                                ]}
                            >
                                {fullName} ({espec})
                            </List.Item>
                        );
                    }}
                />
            </div>

            {/* BOTONES FOOTER */}
            <div style={{ textAlign: 'right' }}>
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                    Cancelar
                </Button>
                <Button type="primary" loading={loading} onClick={handleSave}>
                    Guardar
                </Button>
            </div>
        </Modal>
    );
};

export default EditarPeritosModal;
