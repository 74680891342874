import React, { useState, useEffect } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    DatePicker,
    Switch,
    Row,
    Col,
    message
} from 'antd';
import dayjs from 'dayjs';
import axiosInstance from '../services/axiosConfig';

const parseDate = (dateString) => {
    return dateString ? dayjs(dateString) : null;
};

const EditarProcedimientoModal = ({ visible, onClose, procedimiento, onUpdate }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // Listas para Juzgado y Especialidad
    const [juzgados, setJuzgados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);

    // Búsqueda controlada
    const [juzgadoSearch, setJuzgadoSearch] = useState('');
    const [especialidadSearch, setEspecialidadSearch] = useState('');

    // 1) Cargar / filtrar Juzgados
    const fetchJuzgados = async (searchVal = '') => {
        try {
            const res = await axiosInstance.get('juzgados/', {
                params: { search: searchVal, limit: 20 }
            });
            setJuzgados(res.data);
        } catch (error) {
            console.error('Error al cargar juzgados:', error);
        }
    };

    // 2) Cargar / filtrar Especialidades
    const fetchEspecialidades = async (searchVal = '') => {
        try {
            const res = await axiosInstance.get('especialidades/', {
                params: { search: searchVal, limit: 20 }
            });
            setEspecialidades(res.data);
        } catch (error) {
            console.error('Error al cargar especialidades:', error);
        }
    };

    // Al abrir el modal, inicializamos campos y cargamos listas
    useEffect(() => {
        if (visible && procedimiento) {
            // Cargar el form con los valores actuales
            form.setFieldsValue({
                numero_procedimiento: procedimiento.numero_procedimiento,
                numero_episodio_iml: procedimiento.numero_episodio_iml,
                fecha_asignacion: parseDate(procedimiento.fecha_asignacion),
                fecha_ratificacion: parseDate(procedimiento.fecha_ratificacion),
                control_calidad: procedimiento.control_calidad,
                numero_miembros_evaluados: procedimiento.numero_miembros_evaluados,
                incomparecencias: procedimiento.incomparecencias === 'si',
                ratificacion_solicitada: procedimiento.ratificacion_solicitada === 'si',
                informe_ratificado: procedimiento.informe_ratificado === 'si',
                // Juzgado y Especialidad -> almacenamos solo el ID
                juzgado: procedimiento.juzgado || null,
                especialidad: procedimiento.especialidad || null
            });
            // Cargamos listados base
            fetchJuzgados('');
            fetchEspecialidades('');
        }
    }, [visible, procedimiento, form]);

    // Manejo de la acción principal (OK)
    const handleOk = () => {
        form.submit();
    };

    // Manejo del envío del formulario
    const handleFinish = async (values) => {
        /*
          values = {
            numero_procedimiento: '...',
            numero_episodio_iml: '...',
            fecha_asignacion: dayjs()|null,
            fecha_ratificacion: dayjs()|null,
            control_calidad: 'pendiente'|...,
            numero_miembros_evaluados: ...,
            incomparecencias: true/false,
            ratificacion_solicitada: true/false,
            informe_ratificado: true/false,
            juzgado: number (id),
            especialidad: number (id)
          }
        */
        setLoading(true);
        try {
            const payload = {
                numero_procedimiento: values.numero_procedimiento,
                numero_episodio_iml: values.numero_episodio_iml,
                fecha_asignacion: values.fecha_asignacion
                    ? values.fecha_asignacion.toISOString()
                    : null,
                fecha_ratificacion: values.fecha_ratificacion
                    ? values.fecha_ratificacion.toISOString()
                    : null,
                control_calidad: values.control_calidad,
                numero_miembros_evaluados: values.numero_miembros_evaluados,
                incomparecencias: values.incomparecencias ? 'si' : 'no',
                ratificacion_solicitada: values.ratificacion_solicitada ? 'si' : 'no',
                informe_ratificado: values.informe_ratificado ? 'si' : 'no',
                juzgado: values.juzgado,
                especialidad: values.especialidad
            };

            // PATCH a /procedimientos/<id>/
            const response = await axiosInstance.patch(
                `procedimientos/${procedimiento.id}/`,
                payload
            );
            if (response.status === 200) {
                message.success('Procedimiento actualizado con éxito.');
                // Actualizamos en la vista principal (Platform) para reflejar cambios
                onUpdate({ ...procedimiento, ...response.data });
                onClose();
            }
        } catch (error) {
            console.error('Error al editar el procedimiento:', error);
            message.error('No se pudo editar el procedimiento.');
        } finally {
            setLoading(false);
        }
    };

    // Layout: 2 columnas con Row / Col
    return (
        <Modal
            title="Editar Procedimiento"
            visible={visible}
            onCancel={onClose}
            onOk={handleOk}
            okText="Guardar"
            cancelText="Cancelar"
            width={1000}                // <-- Ancho grande del modal
            destroyOnClose
            confirmLoading={loading}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Número de Procedimiento"
                            name="numero_procedimiento"
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                        >
                            <Input placeholder="Ej: 123/2025" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Número de Episodio IML"
                            name="numero_episodio_iml"
                        >
                            <Input placeholder="EP000123" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Juzgado"
                            name="juzgado"
                            rules={[{ required: true, message: 'Seleccione un juzgado' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Juzgado..."
                                onSearch={setJuzgadoSearch}
                                filterOption={false}
                                // Mapea {id, nombre} a <Option>:
                                options={juzgados.map((j) => ({ value: j.id, label: j.nombre }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Especialidad"
                            name="especialidad"
                            rules={[{ required: true, message: 'Seleccione la especialidad' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Especialidad..."
                                onSearch={setEspecialidadSearch}
                                filterOption={false}
                                options={especialidades.map((e) => ({
                                    value: e.id,
                                    label: e.nombre
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Fecha de Asignación"
                            name="fecha_asignacion"
                        >
                            <DatePicker showTime style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Fecha de Ratificación"
                            name="fecha_ratificacion"
                        >
                            <DatePicker showTime style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Control de Calidad"
                            name="control_calidad"
                            rules={[{ required: true, message: 'Seleccione control de calidad' }]}
                        >
                            <Select>
                                <Select.Option value="pendiente">Pendiente</Select.Option>
                                <Select.Option value="aprobado">Aprobado</Select.Option>
                                <Select.Option value="suspendido">Suspendido</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Número de Miembros Evaluados"
                            name="numero_miembros_evaluados"
                        >
                            <Input type="number" min={1} placeholder="Ej: 2" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Incomparecencias"
                            name="incomparecencias"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Ratificación Solicitada"
                            name="ratificacion_solicitada"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Informe Ratificado"
                            name="informe_ratificado"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
};

export default EditarProcedimientoModal;
