import React, { useState, useEffect } from 'react';
import './ResumenEstadistico.css';
import Header from "./Header";
import { DataGrid } from '@mui/x-data-grid';
import { TextField, MenuItem, Select, FormControl, InputLabel, Button, Box } from '@mui/material';
import axiosInstance from '../services/axiosConfig';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ResumenEstadistico = () => {
    const [procedimientos, setProcedimientos] = useState([]);
    const [filteredProcedimientos, setFilteredProcedimientos] = useState([]);
    const [filters, setFilters] = useState({
        fechaCreacionDesde: '',
        fechaCreacionHasta: '',
        fechaAsignacionDesde: '',
        fechaAsignacionHasta: '',
        fechaEntregaDesde: '',
        fechaEntregaHasta: '',
        estado: '',
        juzgado: '',
        especialidad: '',
        numeroProcedimiento: '',
        incomparecencias: ''
    });

    useEffect(() => {

        const formatDate = (dateString) => {
            if (!dateString) return '';
            const datePart = dateString.split('T')[0];
            const [year, month, day] = datePart.split('-');
            return `${day}-${month}-${year}`;
        };

        const estadoLabels = {
            'solicitado': 'Solicitado',
            'asignado_peritos': 'Asignado Peritos',
            'informe_entregado': 'Informe Entregado',
            'pendiente_descarga': 'Pendiente Descarga',
            'informe_descargado': 'Informe Descargado',
        };

        const fetchProcedimientos = async () => {
            try {
                const response = await axiosInstance.get('procedimientos/');
                const dataConFechasYEstadoFormateados = response.data.map(item => ({
                    ...item,
                    fecha_creacion: formatDate(item.fecha_creacion),
                    fecha_asignacion: formatDate(item.fecha_asignacion),
                    fecha_entrega_informe: formatDate(item.fecha_entrega_informe),
                    fecha_creacion_original: item.fecha_creacion,
                    fecha_asignacion_original: item.fecha_asignacion,
                    fecha_entrega_informe_original: item.fecha_entrega_informe,
                    estado_label: estadoLabels[item.estado] || item.estado,
                }));
                setProcedimientos(dataConFechasYEstadoFormateados);
                setFilteredProcedimientos(dataConFechasYEstadoFormateados);
            } catch (error) {
                console.error('Error al cargar los procedimientos', error);
            }
        };

        fetchProcedimientos();
    }, []);

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const applyFilters = () => {
        let filtered = procedimientos;

        if (filters.fechaCreacionDesde) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_creacion_original) >= new Date(filters.fechaCreacionDesde)
            );
        }
        if (filters.fechaCreacionHasta) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_creacion_original) <= new Date(filters.fechaCreacionHasta)
            );
        }
        if (filters.fechaAsignacionDesde) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_asignacion_original) >= new Date(filters.fechaAsignacionDesde)
            );
        }
        if (filters.fechaAsignacionHasta) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_asignacion_original) <= new Date(filters.fechaAsignacionHasta)
            );
        }
        if (filters.fechaEntregaDesde) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_entrega_informe_original) >= new Date(filters.fechaEntregaDesde)
            );
        }
        if (filters.fechaEntregaHasta) {
            filtered = filtered.filter(procedimiento =>
                new Date(procedimiento.fecha_entrega_informe_original) <= new Date(filters.fechaEntregaHasta)
            );
        }
        if (filters.estado) {
            filtered = filtered.filter(procedimiento =>
                procedimiento.estado === filters.estado
            );
        }
        if (filters.juzgado) {
            filtered = filtered.filter(procedimiento =>
                procedimiento.juzgado_nombre.toLowerCase().includes(filters.juzgado.toLowerCase())
            );
        }
        if (filters.especialidad) {
            filtered = filtered.filter(procedimiento =>
                procedimiento.especialidad_nombre.toLowerCase().includes(filters.especialidad.toLowerCase())
            );
        }

        if (filters.numeroProcedimiento) {
            filtered = filtered.filter(procedimiento =>
                procedimiento.numero_procedimiento.toLowerCase().includes(filters.numeroProcedimiento.toLowerCase())
            );
        }

        if (filters.incomparecencias) {
            filtered = filtered.filter(procedimiento =>
                procedimiento.incomparecencias === filters.incomparecencias
            );
        }

        setFilteredProcedimientos(filtered);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'estado_label', headerName: 'Estado', width: 170 },
        { field: 'especialidad_nombre', headerName: 'Especialidad', width: 150 },
        { field: 'juzgado_nombre', headerName: 'Juzgado', width: 150 },
        { field: 'numero_procedimiento', headerName: 'Nº Procedimiento', width: 310 },
        { field: 'fecha_creacion', headerName: 'Fecha de Creación', width: 180 },
        { field: 'fecha_asignacion', headerName: 'Fecha de Asignación', width: 180 },
        { field: 'fecha_entrega_informe', headerName: 'Fecha de Entrega del Informe', width: 210 },
        { field: 'numero_miembros_evaluados', headerName: 'N.º de Miembros', width: 150 },
    ];

    const csvHeaders = columns.map(column => ({ label: column.headerName, key: column.field }));

    const totalProcedimientosAsignados = procedimientos.length;
    const totalProcedimientosInformeEntregado = procedimientos.filter(
        procedimiento => procedimiento.estado === 'informe_entregado' || procedimiento.estado === 'informe_descargado'
    ).length;

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const procedimientosAsignadosMesActual = procedimientos.filter(procedimiento => {
        const fechaAsignacion = procedimiento.fecha_asignacion_original;
        if (!fechaAsignacion) return false;
        const fecha = new Date(fechaAsignacion);
        return fecha.getMonth() === currentMonth && fecha.getFullYear() === currentYear;
    }).length;

    const procedimientosEntregadosMesActual = procedimientos.filter(procedimiento => {
        const fechaEntrega = procedimiento.fecha_entrega_informe_original;
        if (!fechaEntrega) return false;
        const fecha = new Date(fechaEntrega);
        return fecha.getMonth() === currentMonth && fecha.getFullYear() === currentYear;
    }).length;

    const exportToExcel = () => {
        const dataToExport = filteredProcedimientos.map(procedimiento => {
            const obj = {};
            columns.forEach(column => {
                obj[column.headerName] = procedimiento[column.field];
            });
            return obj;
        });

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Resumen");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'resumen_estadistico.xlsx');
    };

    return (
        <div className="resumen-container">
            <Header />

            <div className="resumen-content">
                <h2>Resumen Estadístico</h2>

                <Box sx={{ marginBottom: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }} className="resumen-filters">
                    <TextField
                        label="Fecha Creación Desde"
                        type="date"
                        name="fechaCreacionDesde"
                        value={filters.fechaCreacionDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Creación Hasta"
                        type="date"
                        name="fechaCreacionHasta"
                        value={filters.fechaCreacionHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Asignación Desde"
                        type="date"
                        name="fechaAsignacionDesde"
                        value={filters.fechaAsignacionDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Asignación Hasta"
                        type="date"
                        name="fechaAsignacionHasta"
                        value={filters.fechaAsignacionHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Entrega Desde"
                        type="date"
                        name="fechaEntregaDesde"
                        value={filters.fechaEntregaDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Entrega Hasta"
                        type="date"
                        name="fechaEntregaHasta"
                        value={filters.fechaEntregaHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />

                    <FormControl sx={{ minWidth: 120 }} className="resumen-filter-item">
                        <InputLabel>Estado</InputLabel>
                        <Select
                            name="estado"
                            value={filters.estado}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="solicitado">Solicitado</MenuItem>
                            <MenuItem value="asignado_peritos">Asignado Peritos</MenuItem>
                            <MenuItem value="informe_entregado">Informe Entregado</MenuItem>
                            <MenuItem value="pendiente_descarga">Pendiente Descarga</MenuItem>
                            <MenuItem value="informe_descargado">Informe Descargado</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Juzgado"
                        name="juzgado"
                        value={filters.juzgado}
                        onChange={handleFilterChange}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Especialidad"
                        name="especialidad"
                        value={filters.especialidad}
                        onChange={handleFilterChange}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Nº Procedimiento"
                        name="numeroProcedimiento"
                        value={filters.numeroProcedimiento}
                        onChange={handleFilterChange}
                        className="resumen-filter-item"
                    />
                    <FormControl sx={{ minWidth: 120 }} className="resumen-filter-item">
                        <InputLabel>Incomparecencias</InputLabel>
                        <Select
                            name="incomparecencias"
                            value={filters.incomparecencias}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="si">Sí</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                        </Select>
                    </FormControl>

                    <Button variant="contained" onClick={applyFilters} className="resumen-filter-apply">Aplicar Filtros</Button>

                    <CSVLink
                        data={filteredProcedimientos}
                        headers={csvHeaders}
                        filename={"resumen_estadistico.csv"}
                        className="resumen-export-btn resumen-export-csv"
                    >
                        Exportar CSV
                    </CSVLink>

                    <button
                        onClick={exportToExcel}
                        className="resumen-export-btn resumen-export-excel"
                    >
                        Exportar Excel
                    </button>
                </Box>

                <div style={{ height: 400, width: '100%' }} className="resumen-grid-container">
                    <DataGrid
                        rows={filteredProcedimientos}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        disableColumnMenu
                    />
                </div>

                <div className="resumen-stats">
                    <div className="stat-card stat-card-1">
                        <h3>Total Procedimientos Asignados</h3>
                        <p>{totalProcedimientosAsignados}</p>
                    </div>
                    <div className="stat-card stat-card-2">
                        <h3>Procedimientos con Informe Entregado</h3>
                        <p>{totalProcedimientosInformeEntregado}</p>
                    </div>
                    <div className="stat-card stat-card-3">
                        <h3>Procedimientos Asignados este Mes</h3>
                        <p>{procedimientosAsignadosMesActual}</p>
                    </div>
                    <div className="stat-card stat-card-4">
                        <h3>Procedimientos Entregados este Mes</h3>
                        <p>{procedimientosEntregadosMesActual}</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ResumenEstadistico;
